import { useState, useEffect } from 'react';

const useFiles = (localFileManager) => {
  const [files, setFiles] = useState(localFileManager.list());

  const onFilesChanged = (newList) => {
    setFiles(newList);
  }
  useEffect(() => {
    localFileManager.addObserver(onFilesChanged);
    return () => {
      localFileManager.remObserver(onFilesChanged);
    }
  }, []);

  const refresh = () => {
    setFiles(localFileManager.list());
  }

  return {
    files,
    refresh,
  };
}

export default useFiles;
