import React, { useEffect, useRef } from 'react';
import { useSvgContext } from '../context/useSvgContext';
import { useSettingsContext } from '../context/useSettingsContext';

import {vec_sub, vec_length} from '../math';

import './Player.css';

const PLAYER_RADIUS = 4 * 8.66 / 12 / 3;  // later, use this in some way

const Player = ({team, jersey, name, x, y, offsetTouchMove, showNumbers, showNames, color, moveFunc}) => {
  const {pixelData, pixel2svg, svg2pixel} = useSvgContext() || {pixelData: {pixelWidth: 1, minTouchSize: 1}, pixel2svg: () => 1, svg2pixel: () => 1};
  const settings = useSettingsContext();
  const {pixelWidth, minTouchSize} = pixelData;

  const numberFontSize = 0.9 * minTouchSize;
  const nameFontSize = Math.min(20 * pixelWidth, 4);

  // https://stackoverflow.com/questions/45678190/dynamically-disabling-touch-action-overscroll-for-svg-elements
  // https://stackoverflow.com/questions/49541173/how-to-prevent-default-handling-of-touch-events
  const touchStart = (e) => {
    // prevent touch-action 'auto' behavior
    e.preventDefault();
    e.stopPropagation();
  }
  let moveCount = 0;
  let moveDist = 0;
  let didClickEdit = false;
  const moveData = {};
  const down = (e) => {
    console.log('down', e);
    //didClickEdit = e.target.nodeName === 'text' && e.target.innerHTML === 'EDIT';
    didClickEdit = e.target.nodeName === 'text';
    settings.vdebug('player down');
    if (e.pointerType === 'pen') return;  // pen always draws
    if (!e.isPrimary) return;
    e.stopPropagation();
    window.addEventListener('pointermove', move, false);
    window.addEventListener('pointerup', up, false);
    moveFunc('down', team, jersey, e.clientX, e.clientY);
    moveCount = 0;
    moveDist = 0;
    moveData.lastX = e.clientX;
    moveData.lastY = e.clientY;
  }
  const move = (e) => {
    settings.vdebug('player move');
    if (!e.isPrimary) return;

    let y = e.clientY;
    if (offsetTouchMove && moveCount >= 10) {
      const touchOffset = (e.pointerType === 'touch' ? -40 : 0);
      y += touchOffset;
    }
    moveFunc('move', team, jersey, e.clientX, y);

    const dx = e.clientX - moveData.lastX;
    const dy = e.clientY - moveData.lastY;
    moveDist += Math.sqrt(dx*dx + dy*dy);

    moveData.lastX = e.clientX;
    moveData.lastY = e.clientY;
    moveCount++;
  }
  const up = (e) => {
    settings.vdebug('player up');
    if (!e.isPrimary) return;
    window.removeEventListener('pointermove', move, false);
    window.removeEventListener('pointerup', up, false);
    moveFunc('up', team, jersey, e.clientX, e.clientY, didClickEdit);
  }

  // disable pan-zoom if touch is on player element
  const gRef = useRef();
  useEffect(() => {
    if (!gRef) return;
    gRef.current.addEventListener('touchstart', touchStart, {passive: false});
    return () => {
      if (gRef.current) {
        gRef.current.removeEventListener('touchstart', touchStart, {passive: false});
      }
    };
  }, []);

  //const nameOffset = 1.7 * nameFontSize;
  const nameOffset = nameFontSize + minTouchSize;

  return (
    <g ref={gRef} className="player" onPointerDown={down} transform={`translate(${x}, ${y})`}>
      <circle cx={0} cy={0} r={minTouchSize} fill="rgba(255,255,255,0.025)" />
      <circle cx={0} cy={0} r={1} fill={color} stroke="black" strokeWidth={0.1*minTouchSize}/>
      {showNumbers && (
        <text x={0} y={0.3*minTouchSize} textAnchor="middle" fontSize={numberFontSize} fill="white">{jersey}</text>
      )}
      {showNames && (
        <>
          {team==='home' && name && (
            <text x="0" y={nameOffset} textAnchor="middle" fontSize={nameFontSize} fill="white">{name}</text>
          )}
          {team==='home' && !name && (
            <text x="0" y={nameOffset} textAnchor="middle" fontSize={nameFontSize} fill="white">EDIT</text>
          )}
        </>
      )}
    </g>
  );
}

export default Player;
