import {vec_normalize, vec_perpendicular, vec_add, vec_scale, vec_sub, vec_negate, vec_length, vec_dot} from './math';

export const calculateEvenPoints = (path, spacing) => {
  const evenPoints = [];
  let distanceTravelled = 0;

  // add first point
  evenPoints.push([path[0], path[1]]);

  for (let i = 0; i < path.length - 2; i+=2) {
    const dx = path[i+2+0] - path[i+0+0];
    const dy = path[i+2+1] - path[i+0+1];
    const segmentLength = Math.sqrt(dx * dx + dy * dy);

    while (distanceTravelled + spacing < segmentLength) {
      const t = (distanceTravelled + spacing) / segmentLength;
      const x = path[i+0] + t * dx;
      const y = path[i+1] + t * dy;

      evenPoints.push([x, y]);
      distanceTravelled += spacing;
    }

    distanceTravelled -= segmentLength;
  }

  evenPoints.push([path.at(-2), path.at(-1)]);

  return evenPoints;
}

const createDribbleStroke = (pts, pixelWidth) => {
  const dist = 2;
  const evenPoints = calculateEvenPoints(pts, dist);
  // https://stackoverflow.com/questions/42441472/draw-a-squiggly-line-in-svg
  let result = '';
  for(let i=0;i<evenPoints.length-1;i++) {
    if (i===0) {
      result += `M${evenPoints[i][0]},${evenPoints[i][1]}`;
    } else {
      const midPoint = vec_scale(0.5, vec_add(evenPoints[i], evenPoints[i+1]));
      const perpScale = i < evenPoints.length-3 ? 0.7 : 0.2;
      const perp = (
        vec_add(
          midPoint,
          vec_scale(
            ((i%2) ? 1 : -1) * perpScale * dist,
            vec_normalize(
              vec_perpendicular(
                vec_sub(evenPoints[i+1], evenPoints[i])
              )
            )
          )
        )
      );
      result += `Q ${perp[0]},${perp[1]},${evenPoints[i+1][0]},${evenPoints[i+1][1]}`;
      //result += `L ${evenPoints[i][0]} ${evenPoints[i][1]} `;
    }
  }
  return result;
  //return "M 0 0 H 20 V 30";
}

export const pathDataFromStroke = (pts, style, pixelWidth) => {
  if (style === 'Dribble') {
    return createDribbleStroke(pts, pixelWidth);
  } else {
    const ptsToDraw = style !== 'Pass' ? pts : [pts.at(0), pts.at(1), pts.at(-2), pts.at(-1)];
    let result = '';
    for(let i=0;i<ptsToDraw.length;i+=2) {
      if (i===0) {
        result += `M${ptsToDraw[i+0]},${ptsToDraw[i+1]}`;
      } else {
        result += `L${ptsToDraw[i+0]},${ptsToDraw[i+1]}`;
      }
    }
    return result;
  }
}

export const arrowDataFromStroke = (originalPts, style, pixelWidth) => {
  const dist = 1;
  const ptsToDraw = style !== 'Pass' ? originalPts : [originalPts.at(0), originalPts.at(1), originalPts.at(-2), originalPts.at(-1)];
  const pts = calculateEvenPoints(ptsToDraw, dist);

  if (pts.length < 4) {
    return '';
  }

  const vec = vec_scale(
    Math.min(3, 50 * pixelWidth),
    vec_normalize(
      vec_sub(pts.at(-2), pts.at(-1))
    )
  );
  const perp = vec_scale(0.5, vec_perpendicular(vec));
  const lastPt = pts.at(-1);
  const arrowPts = [
    ...vec_add(vec_add(lastPt, vec), perp),
    ...lastPt,
    ...vec_add(vec_add(lastPt, vec), vec_negate(perp))
  ]

  let result = '';
  for(let i=0;i<arrowPts.length;i+=2) {
    result += `${arrowPts[i]}, ${arrowPts[i+1]} `;
  }
  return result;
}

export const isScribble = (line, strokes) => {
  const SCRIBBLE_THRESHOLD = 3;
  let scribbledOnStrokes = [];
  let touchedStrokes = [];
  strokes.forEach((s, index) => {
    const hits = intersectPolylines(line, s);
    if (hits.length >= SCRIBBLE_THRESHOLD) {
      scribbledOnStrokes.push(index);
    }
    if (hits.length > 0) {
      // may need to extend this to also include any point contained in scribble bbox or convex hull
      touchedStrokes.push(index);
    }
  });
  return [scribbledOnStrokes, touchedStrokes];
}

const intersectPolylines = (polyline1, polyline2) => {
  const intersections = [];

  for (let i = 0; i < polyline1.length - 2; i += 2) {
    const a1x = polyline1[i];
    const a1y = polyline1[i + 1];
    const a2x = polyline1[i + 2];
    const a2y = polyline1[i + 3];

    for (let j = 0; j < polyline2.length - 2; j += 2) {
      const b1x = polyline2[j];
      const b1y = polyline2[j + 1];
      const b2x = polyline2[j + 2];
      const b2y = polyline2[j + 3];

      const intersection = intersectLines(a1x, a1y, a2x, a2y, b1x, b1y, b2x, b2y);

      if (intersection) {
        intersections.push(intersection);
      }
    }
  }

  return intersections;
}

const intersectLines = (a1x, a1y, a2x, a2y, b1x, b1y, b2x, b2y) => {
  const det = (a2x - a1x) * (b2y - b1y) - (a2y - a1y) * (b2x - b1x);

  if (det === 0) { // Lines are parallel or coincident
    return null;
  }

  const t = ((b1x - a1x) * (b2y - b1y) - (b1y - a1y) * (b2x - b1x)) / det;
  const u = -((a1x - b1x) * (a2y - a1y) - (a1y - b1y) * (a2x - a1x)) / det;

  if (t >= 0 && t <= 1 && u >= 0 && u <= 1) {
    const intersectX = a1x + t * (a2x - a1x);
    const intersectY = a1y + t * (a2y - a1y);
    return [intersectX, intersectY];
  }

  return null;
}
