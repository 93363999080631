import React, { useState, useRef, useEffect } from 'react';

import FormGroup from '@mui/material/FormGroup';
import FormLabel from '@mui/material/FormLabel';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';

import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';


// General
//  Field
//    - 11v11 soccer
//    - 6v6 indoor soccer
// 
// Touch
//   SWITCH - offset objects from touch point
// 
// Gestures
//   SWITCH - tap to confirm scribble erase
// Pen
//   SWITCH - pen always draws paths
// 

const FIELDS = [
  'Outdoor - 11v11',
  'Outdoor - 9v9',
  'Outdoor - 7v7',
  'Indoor - 6v6',
  'Futsal',
];

export const DEFAULT_SETTINGS = {
  field: 'soccer-11v11',
  showPlayerNumbers: false,
  showPlayerNames: false,
  offsetTouchMove: true,
  penAlwaysDraws: true,
  confirmScribbleErase: false,
  field: FIELDS[0],
};

const SettingsView = ({settings, handleClose, handleOkay}) => {
  const [state, setState] = useState(settings || DEFAULT_SETTINGS);

  const updateField = e => {
    setState(prev => {
      return {
        ...prev,
        field: e.target.value,
      }
    });
  }

  const handleSwitch = e => {
    setState(prev => {
      const newState = {
        ...prev,
        [e.target.name]: e.target.checked
      };
      return newState;
    });
  }

  const reload = () => {
    window.location.reload();
  }

  return (
    <Dialog
      open={true}
      onClose={handleClose}
      scroll="paper"
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
    >
      <DialogTitle id="scroll-dialog-title">Settings</DialogTitle>
      <DialogContent dividers={true}>
        <FormControl>
          <InputLabel id="demo-simple-select-label">Field</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={state.field}
            onChange={updateField}
            label="Field"
            autoWidth
          >
            {FIELDS.map(field => (<MenuItem value={field}>{field}</MenuItem>))}
          </Select>
        </FormControl>
        <Divider />
        <FormControl>
          <FormLabel component="legend">View</FormLabel>
          <FormGroup>
            <FormControlLabel control={<Switch checked={state.showPlayerNumbers} onChange={handleSwitch} name="showPlayerNumbers" />} label="Show player numbers" />
            <FormControlLabel control={<Switch checked={state.showPlayerNames} onChange={handleSwitch} name="showPlayerNames" />} label="Show player names" />
          </FormGroup>
        </FormControl>
        <Divider />
        <FormControl>
          <FormLabel component="legend">Touch</FormLabel>
          <FormGroup>
            <FormControlLabel control={<Switch checked={state.offsetTouchMove} onChange={handleSwitch} name="offsetTouchMove" />} label="Offset objects being dragged by touch" />
          </FormGroup>
        </FormControl>
        <Divider />
        <FormControl>
          <FormLabel component="legend">Pen</FormLabel>
          <FormGroup>
            <FormControlLabel control={<Switch checked={state.penAlwaysDraws} onChange={handleSwitch} name="penAlwaysDraws" />} label="Pen always draws" />
            <FormControlLabel control={<Switch checked={state.confirmScribbleErase} onChange={handleSwitch} name="confirmScribbleErase" />} label="Confirm scribble erase" />
          </FormGroup>
        </FormControl>
        <Divider/>
        <Button variant="contained" onClick={reload}>Reload App</Button>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button variant="contained" onClick={() => handleOkay(state)}>Okay</Button>
      </DialogActions>
    </Dialog>
  );
}

export default SettingsView;
