import React, { useEffect, useRef } from 'react';
import { useSvgContext } from '../context/useSvgContext';
import { useSettingsContext } from '../context/useSettingsContext';

const BALL_RADIUS = 4 * 8.66 / 12 / 3;  // later, use this in some way

const Ball = ({x, y, moveFunc}) => {
  const {pixelData, pixel2svg, svg2pixel} = useSvgContext();
  const settings = useSettingsContext();
  const {pixelWidth, minTouchSize} = pixelData;

  // https://stackoverflow.com/questions/45678190/dynamically-disabling-touch-action-overscroll-for-svg-elements
  // https://stackoverflow.com/questions/49541173/how-to-prevent-default-handling-of-touch-events
  const touchStart = (e) => {
    // prevent touch-action 'auto' behavior
    e.preventDefault();
    e.stopPropagation();
  }
  const down = (e) => {
    if (e.pointerType === 'pen') return;  // pen always draws
    if (!e.isPrimary) return;
    e.stopPropagation();
    window.addEventListener('pointermove', move, false);
    window.addEventListener('pointerup', up, false);
    const touchOffset = (settings.offsetTouchMove && e.pointerType === 'touch' ? 40 : 0);
    moveFunc('down', e.clientX, e.clientY + touchOffset);
  }
  const move = (e) => {
    if (!e.isPrimary) return;
    moveFunc('move', e.clientX, e.clientY);
  }
  const up = (e) => {
    if (!e.isPrimary) return;
    window.removeEventListener('pointermove', move, false);
    window.removeEventListener('pointerup', up, false);
    moveFunc('up', e.clientX, e.clientY);
  }

  // disable pan-zoom if touch is on player element
  const gRef = useRef();
  useEffect(() => {
    if (!gRef) return;
    gRef.current.addEventListener('touchstart', touchStart, {passive: false});
    return () => {
      if (gRef.current) {
        gRef.current.removeEventListener('touchstart', touchStart, {passive: false});
      }
    };
  }, []);

  const ballScale = Math.max(0.0001, 0.00015 * minTouchSize);

  return (
    <g ref={gRef} style={{cursor: 'grab'}} onPointerDown={down} transform={`translate(${x}, ${y})`}>
      <circle cx={0} cy={0} r={minTouchSize} fill="rgba(255,255,255,0.0025)" />
      <g style={{transform:`scale(${ballScale})`}}>
        <g stroke="#000" strokeWidth="24">
          <circle fill="#fff" r="2376"/>
          <path fill="none" d="m-1643-1716 155 158m-550 2364c231 231 538 195 826 202m-524-2040c-491 351-610 1064-592 1060m1216-1008c-51 373 84 783 364 1220m-107-2289c157-157 466-267 873-329m-528 4112c-50 132-37 315-8 510m62-3883c282 32 792 74 1196 303m-404 2644c310 173 649 247 1060 180m-340-2008c-242 334-534 645-872 936m1109-2119c-111-207-296-375-499-534m1146 1281c100 3 197 44 290 141m-438 495c158 297 181 718 204 1140"/>
        </g>
        <path fill="#000" d="m-1624-1700c243-153 498-303 856-424 141 117 253 307 372 492-288 275-562 544-724 756-274-25-410-2-740-60 3-244 84-499 236-764zm2904-40c271 248 537 498 724 788-55 262-105 553-180 704-234-35-536-125-820-200-138-357-231-625-340-924 210-156 417-296 616-368zm-3273 3033a2376 2376 0 0 1-378-1392l59-7c54 342 124 674 311 928-36 179-2 323 51 458zm1197-1125c365 60 717 120 1060 180 106 333 120 667 156 1000-263 218-625 287-944 420-372-240-523-508-736-768 122-281 257-561 464-832zm3013 678a2376 2376 0 0 1-925 1147l-116-5c84-127 114-297 118-488 232-111 464-463 696-772 86 30 159 72 227 118zm-2287 1527a2376 2376 0 0 1-993-251c199 74 367 143 542 83 53 75 176 134 451 168z"/>
      </g>
    </g>
  );
}

export default Ball;
