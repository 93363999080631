import dayjs from 'dayjs';

import './FileManager.css';

var utc = require('dayjs/plugin/utc');
var timezone = require('dayjs/plugin/timezone'); // dependent on utc plugin
var relativeTime = require('dayjs/plugin/relativeTime');
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(relativeTime);

const formatDate = (dateStr) => {
  // https://day.js.org/docs/en/display/from-now
  return dayjs(dateStr).fromNow();
  //return dayjs(dateStr).format('MMM DD h:mm A');
}

const formatName = (name) => {
  if (name === undefined || name === null || name === 'unnamed') {
    return <span style={{opacity:0.65,fontStyle:'italic'}}>Unnamed</span>;
  } else {
    return name;
  }
}

const FileManager = ({files, currentBoardId, onClickCreateBoard, onClickBoardTile, onClickLongBoardTile}) => {
  return (
    <div className="file-manager">
      <div className="board-item add-board" onClick={onClickCreateBoard} title="New board">+</div>
      <div className="boards-list">
        {files.map(x => (
          <div
            key={x.boardId}
            className={`board-item${x.boardId===currentBoardId?' board-selected':''}`}
            data-id={x.boardId}
            onClick={onClickBoardTile}
            onContextMenu={onClickLongBoardTile}
            title={new Date(x.dateCreated)}
          >
            <div>{formatName(x.name)}</div>
            <div>{formatDate(x.dateCreated)}</div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default FileManager;
