import React, { useEffect, useRef } from 'react';

export const CircleFader = ({x, y, onDone}) => {
  const animRadius = useRef();
  const animOpacity = useRef();

  const done = () => {
    if (onDone)
      onDone();
  }

  useEffect(() => {
    if (animRadius.current && animOpacity.current) {
      animRadius.current.addEventListener('endEvent', done, false);
      animRadius.current.beginElement();
      animOpacity.current.beginElement();
    }
    return () => {
      if (animRadius.current)
        animRadius.current.removeEventListener('endEvent', done, false);
    }
  }, [animRadius, animOpacity]);

  return (
    <circle cx={x} cy={y} r="0" stroke="white" fill="transparent" style={{pointerEvents:'none'}}>
      <animate
        ref={animRadius}
        attributeName="r"
        dur="1s"
        fill="freeze"
        from="0" to="10"
      />
      <animate
        ref={animOpacity}
        attributeName="opacity"
        dur="1s"
        fill="freeze"
        from="1" to="0"
      />
    </circle>
  )
};
