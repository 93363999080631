const LocalFileManager = () => {
  const listeners = [];
  const addObserver = (callback) => { listeners.push(callback); }
  const remObserver = (callback) => {
    const index = listeners.indexOf(callback);
    if (index > -1) {
      listeners.splice(index, 1);
    }
  }
  const notifyListChanged = () => {
    const newList = list();
    listeners.forEach(callback => callback(newList));
  }

  const PREFIX = 'board_';
  const makeKey = id => `${PREFIX}${id}`;
  const list = () => {
    let items = [];
    for (let i = 0; i < localStorage.length; i++) {
      let key = localStorage.key(i);
      if (!key.startsWith(PREFIX)) {
        continue;
      }
      let value = JSON.parse(localStorage.getItem(key));
      items.push(value); // key is same as 'boardId' within value
    }

    items.sort((a, b) => new Date(b.dateCreated) - new Date(a.dateCreated));

    return items;
  }
  const create = (data) => {
    const id = crypto.randomUUID();
    const now = new Date().toISOString();
    const newFile = {
      'boardId': id,
      'dateCreated': now,
      'dateModified': now,
      'name': 'Unnamed',
      //'owner': null, // todo later..
      data,
    };
    localStorage.setItem(makeKey(id), JSON.stringify(newFile));
    notifyListChanged();
    return newFile;
  }
  const read = (id) => {
    const file = localStorage.getItem(makeKey(id));
    return JSON.parse(file);
  }
  const update = (id, data) => {
    try {
      const file = JSON.parse(localStorage.getItem(makeKey(id)));
      file.dateModified = new Date().toISOString();
      file.data = data;
      localStorage.setItem(makeKey(id), JSON.stringify(file));
    } catch(e) {
      console.warn('exception while updating file', id);
    }
  }
  const deleteFileWithoutNotify = (id) => {
    localStorage.removeItem(makeKey(id));
  }
  const deleteFile = (id) => {
    deleteFileWithoutNotify(id);
    notifyListChanged();
  }

  const deleteAll = () => {
    // just for early testing.. remove later?
    const items = list();
    items.forEach(x => deleteFileWithoutNotify(x.boardId));
    notifyListChanged();
  }

  const deleteAllWithoutNotify = () => {
    const items = list();
    items.forEach(x => deleteFileWithoutNotify(x.boardId));
  }
  //deleteAllWithoutNotify();

  return {
    list,
    create,
    read,
    update,
    deleteFile,
    deleteAll,

    addObserver,
    remObserver,
  };
}

export default LocalFileManager;
