import { createContext, useContext } from 'react'

const SettingsContext = createContext();

export const SettingsProvider = ({settings, children}) => {
  return (
    <SettingsContext.Provider value={settings}>
      {children}
    </SettingsContext.Provider>
  );
}

export const useSettingsContext = () => {
  return useContext(SettingsContext);
}
