import React, { useState, useRef, useEffect } from 'react';
import Button from '@mui/material/Button';

export const TextBoxInput = (args) => {
  const [text, setText] = useState(args.text || '');

  const textAreaRef = useRef();

  useEffect(() => {
    if (textAreaRef.current) {
      textAreaRef.current.select();
    }
  }, []);

  const handleKeyDown = e => {
    if (e.ctrlKey && e.key === 'Enter') {
      if (args.onOk) {
        args.onOk(validateText(text));
      }
    }
  }

  const validateText = text => {
    return (text.trim().length === 0) ? 'Click to edit' : text;
  }

  return (
    <>
      <div
        style={{
          position: 'absolute',
          display: 'fixed',
          inset: '0px',
          background: 'rgba(0,0,0,0.8)',
        }
      }>
        <div style={{
          display: 'flex',
          flexFlow: "column",
          justifyContent: "top",
          alignItems: "center",
          position: 'absolute',
          inset: '1em',
          background: 'white',
          borderRadius: '4px'
        }}>
          <textarea
            ref={textAreaRef}
            style={{width: '90%', height: '40%'}}
            value={text}
            onChange={e => setText(e.target.value)}
            onKeyDown={handleKeyDown}
            placeholder="Enter text here"
          />
          <div style={{marginTop: '1em', display: 'flex'}}>
            <Button onClick={args.onCancel}>Cancel</Button>
            <Button onClick={() => args.onOk(validateText(text))} variant="contained">Ok</Button>
          </div>
        </div>
      </div>
    </>
  );
}

const TextBox = ({text, x, y, w, h, angle, onClick, index, moveFunc}) => {
  const down = (e) => {
    if (e.pointerType === 'pen') return;  // pen always draws
    if (!e.isPrimary) return;

    e.preventDefault();
    e.stopPropagation();

    window.addEventListener('pointermove', move, false);
    window.addEventListener('pointerup', up, false);
    moveFunc('down', index, e.clientX, e.clientY);
  }

  const move = (e) => {
    if (!e.isPrimary) return;
    moveFunc('move', index, e.clientX, e.clientY);
  }
  const up = (e) => {
    if (!e.isPrimary) return;
    window.removeEventListener('pointermove', move, false);
    window.removeEventListener('pointerup', up, false);
    moveFunc('up', index, e.clientX, e.clientY);
  }

  return (
    <>
      <foreignObject x={x} y={y} width={w} height={1} fontSize="3" style={{overflow:'visible',pointerEvents:'none'}}>
        <div
          xmlns="http://www.w3.org/1999/xhtml"
          style={{
            border: "0.1px solid rgba(0,0,0,0.5)",
            padding: "2px",
            transformOrigin: "top left",
            transform: `scale(0.5) rotate(${angle}deg)`,
            userSelect: 'none',
            pointerEvents: 'auto',
          }}
          onPointerDown={down}
          onClick={onClick}
        >
          {text}
          {/* Defenders: if you are under pressure, a pass back to your goalie can be an easy way to end the attack */}
          {/* <input ref={inputRef} type="hidden" id="myInput" value={text} onInput={e => setText(e.target.value)} /> */}
        </div>
      </foreignObject>
    </>
  );
}

export default TextBox;
