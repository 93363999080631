
import './FileManager.css';

const FileManager = ({files, onClickCreateBoard, onClickBoardTile, onClickLongBoardTile}) => {
  return (
    <div className="file-manager">
      <div className="add-board" onClick={onClickCreateBoard} title="New board">+</div>
      <div className="boards-list">
        {files.map(x => (
          <div
            key={x.boardId}
            className="board-item"
            data-id={x.boardId}
            onClick={onClickBoardTile}
            onContextMenu={onClickLongBoardTile}
          >
            {x.boardId}
          </div>
        ))}
      </div>
    </div>
  );
}

export default FileManager;
